import React from 'react';

import './loader.css';
class FullScreenLoading extends React.Component {
  render() {
    return (
      <>
        {this.props.loading && (
          <div style={{ width: '100vw', height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#f0f2f5',
                zIndex: 500
              }}
            >
              <div
                style={{
                  zIndex: 1000,
                  marginTop: '-100px'
                }}
              >
                <img
                  style={{ color: 'black', width: '150px' }}
                  src={
                    'https://elasticbeanstalk-us-east-2-783675859554.s3.amazonaws.com/CW_Loading_v2.svg'
                  }
                  alt=''
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default FullScreenLoading;
