import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const ManagePageHeaders = lazy(
  () => import('../../systemAdmin/pageOptions/pageHeaders/ManagePageHeaders')
);
const ManagePopups = lazy(
  () => import('../../systemAdmin/pageOptions/popups/ManagePopups')
);
const ManageUsers = lazy(
  () => import('../../systemAdmin/manageUsers/ManageUsers')
);
const ManagePages = lazy(
  () => import('../../systemAdmin/pageOptions/pages/ManagePages')
);
const ReviewDashboard = lazy(
  () => import('../../systemAdmin/reviews/ReviewDashboard')
);
const PageBuilder = lazy(() => import('../../pageBuilder/builder/PageBuilder'));
const ManageConferences = lazy(
  () =>
    import('../../systemAdmin/sessions/createEditSessions/ManageConferences')
);
const ManageConferenceRecordings = lazy(
  () =>
    import('../../systemAdmin/sessions/recordings/ManageConferenceRecordings')
);
const ManageMedia = lazy(() => import('../../systemAdmin/media/ManageMedia'));
const CompanyIntegrations = lazy(
  () => import('../../company/CompanyIntegrations')
);
const ManageRuleSets = lazy(
  () => import('../../systemAdmin/manageRuleSets/ManageRuleSets')
);
const ManageAccessRules = lazy(() => import('../../company/ManageAccessRules'));

const SignInAsAnotherUser = lazy(
  () => import('../../systemAdmin/signInAsAnotherUser/SigninAsAnotherUser')
);
const CoachesAvailabilityInTier = lazy(
  () =>
    import(
      '../../systemAdmin/coachManagement/coachesAvailabilityInTier/CoachesAvailabilityInTier'
    )
);
const CoachOnboardProfiles = lazy(
  () =>
    import(
      '../../systemAdmin/coachManagement/coachOnboarding/CoachOnboardProfiles'
    )
);
const ManageUser = lazy(
  () => import('../../systemAdmin/manageUsers/manageUser/ManageUser')
);
const ManageCourses = lazy(
  () => import('../../courses/manageCourses/ManageCourses')
);
const ManageProducts = lazy(
  () => import('../../systemAdmin/commerce/manageProducts/ManageProducts')
);
const NotificationBlaster = lazy(
  () => import('../../systemAdmin/notificationBlaster/NotificationBlaster')
);
const ManageDiscounts = lazy(
  () => import('../../systemAdmin/commerce/manageDiscounts/ManageDiscounts')
);
const CourseBuilder = lazy(() => import('../../coursesRebuild/CourseBuilder'));
const STTutorialConfiguration = lazy(
  () =>
    import(
      '../../systemAdmin/statTracker/tutorialConfiguration/STTutorialConfiguration'
    )
);
const Notes = lazy(() => import('../../systemAdmin/pageOptions/notes/Notes'));

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path='/reviews' element={<ReviewDashboard />} />
      <Route path='/pageBuilder/managePages' element={<ManagePages />} />
      <Route
        path='/pageBuilder/managePageHeaders'
        element={<ManagePageHeaders />}
      />
      <Route path='/pageBuilder/managePopups' element={<ManagePopups />} />
      <Route path='/users/manageUsers' element={<ManageUsers />} />
      <Route
        path='/users/signInAsAnotherUser'
        element={<SignInAsAnotherUser />}
      />
      <Route
        path='/adminTools/CoachesAvailableInTier/:path'
        element={<CoachesAvailabilityInTier />}
      />
      <Route
        path='/adminTools/profiles/coaches'
        element={<CoachOnboardProfiles />}
      />
      <Route path='/users/user/:id' element={<ManageUser />} />
      <Route path='/courses/manageCourses' element={<ManageCourses />} />
      <Route path='/products/manageProducts' element={<ManageProducts />} />
      <Route path='/notifications/blast' element={<NotificationBlaster />} />
      <Route path='/products/discounts' element={<ManageDiscounts />} />
      <Route path='/courses/builder/:id' element={<CourseBuilder />} />
      <Route path='/pageEditor/:id' element={<PageBuilder />} />
      <Route
        path='/conferences/manageSessions'
        element={<ManageConferences />}
      />
      <Route
        path='/conferences/manageRecordings'
        element={<ManageConferenceRecordings />}
      />
      <Route path='/media/manageMedia' element={<ManageMedia />} />
      <Route
        path='/company/manageIntegrations'
        element={<CompanyIntegrations />}
      />
      <Route path='/company/manageRuleSets' element={<ManageRuleSets />} />
      <Route
        path='/company/manageAccessRules'
        element={<ManageAccessRules />}
      />

      <Route path='/STTutorials' element={<STTutorialConfiguration />} />
      <Route path='/notes' element={<Notes />} />
    </Routes>
  );
};

export default AdminRoutes;
